<template>
    <div class="analysis">
        <div class="width">
            <img src="https://2lc-oss.erlangcha.com/indeximg/1636190483442_1.jpg" alt="" srcset="" class="img">
            <div class="pickerTime">{{pickerTime}}</div>
            <div class="backimg">
                <div class="shuiyin">
                    <div class="dan" v-for="(item,key) in 14" :key="key"><img src="https://2lc-oss.erlangcha.com/indeximg/shuiyinadmin.png" alt=""></div>
                </div>
                <div v-for="(item,key) in ErShopList" :key="key" class="show" :class="'pages' + onCount(key+1)">
                    <img :src="item.shop_info.shop_img" alt="" class="showimg">
                    <span class="showspan">{{item.shop_info.shop_title}}</span>
                    <span class="showdian">点击数:{{item.aggregate}}</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            pickerTime: "", //日期参数
            ErShopList: [],
            per_page: 30,
            paramData: {},
            keys: "",
        };
    },
    mounted() {
        if (this.$route.query.time) {
            this.pickerTime = this.$route.query.time;
        } else {
            this.pickerTime = this.getDay(-1);
        }
        this.onDataList();
    },
    methods: {
        //方法
        onCount(key) {
            if (key > 4) {
                return this.onCount(key - 4);
            } else {
                return key;
            }
        },
        // 获取昨天
        getDay(day) {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds);
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        //数据列表
        onDataList() {
            this.ErShopList = [];
            var param = this.paramData;
            param.pageSize = this.per_page;
            param.date = this.pickerTime;
            this.$service.get(this.$api.getShopAggregate, param, (res) => {
                if (res.code == "200") {
                    this.ErShopList = res.data.data;
                    // this.lvtotal = res.data.count;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.analysis {
    .width {
        width: 1360px;
        height: auto;
        margin: 0 auto;
        position: relative;
        .img {
            width: 100%;
        }
        .pickerTime {
            position: absolute;
            top: 350px;
            left: 0;
            width: 100%;
            height: 100px;
            text-align: center;
            line-height: 100px;
            color: #fff;
            font-size: 60px;
            font-weight: bold;
        }
        .backimg {
            position: absolute;
            top: 550px;
            left: 70px;
            display: flex;
            flex-wrap: wrap;
            text-align: left;
            width: 1550px;
            height: 600px;
            .shuiyin{
                width: 1100px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                position: absolute;
                z-index: 100;
                left: 100px;
                .dan{
                    margin: 0 150px;
                    margin-bottom: 300px;
                    opacity: .2;
                    transform:rotate(30deg);
                }
            }
            .show {
                margin: 10px 7px;
                padding: 15px;
                width: 600px;
                display: flex;
                justify-content: left;
                align-items: center;
                height: 125px;
                overflow: hidden;
                color: #fff;
                border: 4px solid #fff;
            }
            .showimg {
                width: 90px;
                height: 90px;
            }
            .showspan {
                // padding-top: 10px;
                margin-left: 20px;
                font-weight: 500;
                width: 290px;
                font-size: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
            .showdian {
                font-weight: 500;
                font-size: 22px;
                margin-left: auto;
                // padding-top: 20px;
            }
            .pages1 {
                // background: red;
                background: linear-gradient(
                    180deg,
                    #ec6819,
                    #f2901a,
                    #ec6819,
                    #f2901a
                );
            }
            .pages2 {
                background: linear-gradient(
                    180deg,
                    #199fec,
                    #1a78f3,
                    #199fec,
                    #19a2ec
                );
            }
            .pages3 {
                background: linear-gradient(
                    180deg,
                    #b71bf2,
                    #9119ee,
                    #b71bf2,
                    #9119ee
                );
            }
            .pages4 {
                background: linear-gradient(
                    180deg,
                    #ee3358,
                    #ed1a25,
                    #ee3358,
                    #ed1a25
                );
            }
            // .classTwo{
            //     background: red;
            // }
        }
    }
}
</style>
<style lang="scss">
</style>